// Post import scss

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/right-sidebar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
// horizontal nav
@import "custom/structure/horizontal-nav";


//Fonts
@import "custom/fonts/nunito";


//Components
@import "custom/components/mixins";
@import "custom/components/accordions";
@import "custom/components/avatar";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/badge";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/pagination";
@import "custom/components/popover";
@import "custom/components/print";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/switch";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/utilities";
@import "custom/components/widgets";
@import "custom/components/social";
@import "custom/components/steps";
@import "custom/components/preloader";


//Pages
@import "custom/pages/authentication";
@import "custom/pages/buttons-demo";
@import "custom/pages/error";
@import "custom/pages/faq";
@import "custom/pages/grid-demo";
@import "custom/pages/icons-demo";
@import "custom/pages/maintenance";
@import "custom/pages/tasks";
@import "custom/pages/email";


// Vendors
@import "vendor/daterangepicker";
@import "vendor/bootstrap-datepicker.min";
@import "vendor/jquery.toast.min";
@import "vendor/select2.min";
@import "vendor/jquery.toast.min";
@import "vendor/bootstrap-timepicker.min";
@import "vendor/jquery.bootstrap-touchspin.min";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/britechart";
@import "custom/plugins/calendar";
@import "custom/plugins/chartjs";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/dragula";
@import "custom/plugins/dropzone";
@import "custom/plugins/form-wizard";
@import "custom/plugins/maps";
@import "custom/plugins/metis-menu";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/bootstrap-timepicker";
@import "custom/plugins/summernote";
@import "custom/plugins/simplemde";
@import "custom/plugins/typehead";
@import "custom/plugins/sparklines";


// top-bar, nav-bar, search

$app-search-height: 30px;

.app-search {
  padding: calc(#{$topbar-height - $app-search-height} / 2) 0;

  .form-control {
    height: $app-search-height;
  }

  span {
    line-height: $app-search-height;
  }

  button {
    padding-top: unset;
    padding-bottom: unset;
  }
}

.notification-list .noti-icon-badge {
  top: 14px;
  right: 15px;
  height: 9px;
  width: 9px;
}

body[data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link {
  padding: 10px 15px;
  min-height: 46px;
}

// left sidebar, menu

.side-nav .side-nav-link {
  padding: 10px 15px;
}

// content

.content-page {
  padding: 5px 10px 40px;
}

.page-title-box {
  .page-title {
    line-height: 50px;
  }

  .page-title-right {
    margin-top: 8px;
  }
}

// form

label {
  margin-bottom: 0;
  font-size: calc(#{$font-size-base} * 0.9);
}

.custom-control-label {
  font-size: inherit; 
}

// footer
.footer {
  border-top: 0px;
  padding: 10px 15px 10px;
  background-color: $bg-topbar;
}

